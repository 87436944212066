$(document).ready(function () {
    YandexMap();
    InputsFormReservation();
    InputsFormBonusCards();
    SmoothAnchors();
    MobileMenu();
    ModalWindowBonusCard();
    handlerForms();
    downloadingApplication();
});

function downloadingApplication(){
    var smartbannerCookie = Cookies.get('smartbannerCookie');

    if (typeof Cookies.get('smartbannerCookie') === 'undefined') {
        setTimeout(() => {
            if (device.ios()) {
                $('.smartbanner_ios').show();
            } else if (device.android()) {
                $('.smartbanner_android').show();
            }
        }, 300);
    } else {
        $('.smartbanner_ios').hide();
        $('.smartbanner_android').hide();
    }

    $(document).on('click', '.js-smartbanner-close', function () {
        $('.smartbanner_android, .smartbanner_ios').hide();
    });


    $(document).on('click', '.js-smartbanner-download', function () {
        Cookies.set('smartbannerCookie', 'div has been closed', {
            expires: 60
        });
        setTimeout(() => {
            $('.smartbanner_android, .smartbanner_ios').hide();
        }, 300);
    });

}


// Карта на блоке контактов

function YandexMap() {

    ymaps.ready(function () {

        if ($(window).width() > 1024) {

            myMap = new ymaps.Map("map", {

                center: [44.80307176605313, 44.1508],

                zoom: 17,

                controls: []

            });

            myMap.behaviors.disable('scrollZoom');

            // Маркер

            var myPlacemark = new ymaps.Placemark([44.80307176605313, 44.15527785581968], {}, {

                iconLayout: 'default#image',

                iconImageHref: '/images/map-pointer.svg',

                iconImageSize: [35, 35],

            });

            myMap.geoObjects.add(myPlacemark);

        }

        if ($(window).width() <= 1024) {

            myMap = new ymaps.Map("map", {

                center: [45.02, 41.9325],

                zoom: 17,

                controls: []

            });

            myMap.behaviors.disable('scrollZoom');

            // Маркер

            var myPlacemark = new ymaps.Placemark([44.80307176605313, 44.15527785581968], {}, {

                iconLayout: 'default#image',

                iconImageHref: '/images/map-pointer.svg',

                iconImageSize: [35, 35],

            });

            myMap.geoObjects.add(myPlacemark);

        }

    });

}


// Эффект фокуса для полей в форме бронирования столика

function InputsFormReservation() {

    $('.reservation__content .reservation__form .form__input input').focus(function () {
        $(this).parent().find('span').addClass('active');
    });

    $('.reservation__content .reservation__form .form__input input').blur(function () {
        if($(this).attr('name') == 'date'){
            return;
        }

        if ($(this).val() != '') {
            return;
        }

        $(this).parent().find('span').removeClass('active');

    });

    $('#datepicker').datepicker({
        dateFormat : "dd.mm.yy",
        minDate: "+0D",
        monthNames : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
        dayNamesMin : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
        onSelect: function () {
            $('.reservation__content .reservation__form .form__input input[name="date"]').parent().find('span').addClass('active');
        },
        onClose: function(){
            if($(this).val() == ''){
                $(this).parent().find('span').removeClass('active');
            }
            else{
                return;
            }
        }
    });

}


// Анмиация полей ввода при фокусе в форме бронирования столика

function InputsFormBonusCards() {

    $('.modal-window__form .modal-window__input input').focus(function () {

        $(this).addClass('focus');

    });

    $('.modal-window__form .modal-window__input input').blur(function () {

        $(this).removeClass('focus');

    });


    $('body').keypress(function () {

        $('.modal-window__form .modal-window__input input').each(function () {

            if ($(this).val() != '') {

                $(this).addClass('orange');

            }

            if ($(this).val() == '') {

                $(this).removeClass('orange');

            }

        });

    });

    $('body').click(function () {

        $('.modal-window__form .modal-window__input input').each(function () {

            if ($(this).val() != '') {

                $(this).addClass('orange');

            }

            if ($(this).val() == '') {

                $(this).removeClass('orange');

            }

        });

    });

}


// Плавные якоря

function SmoothAnchors() {

    $('.link-anchor').on('click', function (event) {

        event.preventDefault();

        var id = $(this).attr('href');


        if ($(this).attr('data-link') == 'about' || $(this).attr('data-link') == 'bonus') {

            if ($(window).width() <= 600) {

                var top = $(id).offset().top - $('.header').innerHeight();

            }

            var top = $(id).offset().top - 95;

        }

        else {

            if ($(window).width() <= 600) {

                var top = $(id).offset().top - $('.header').innerHeight();

            }

            var top = $(id).offset().top;

        }

        $('body, html').animate({scrollTop: top}, 1000);

    });

}


// Мобильное меню

function MobileMenu() {

    $('.header__button-mobile-menu').click(function () {

        $('.header__menu').toggleClass('open');

        if ($('.header__menu').hasClass('open') == true) {

            $('.header__menu').css('height', ($('.header__menu .menu__item').innerHeight() * $('.header__menu .menu__item').length) + 5);

        }

        else {

            $('.header__menu').css('height', 0);

        }

    });


    $('.header__menu .menu__item').click(function () {

        $('.header__menu').removeClass('open');

        $('.header__menu').css('height', 0);

    });

}


// Анимация полей ввода при фокусе форме заказа бокусной карты

function ModalWindowBonusCard() {

    $('.button-open-window-bonus-card').click(function () {

        $('body').addClass('no-scroll');

        $('.modal-window').addClass('open');

    });


    $('.button-close-window-bonus-card').click(function () {

        $('body').removeClass('no-scroll');

        $('.modal-window').removeClass('open');

    });

}

function handlerForms() {
    window.Parsley.addValidator('tel', {
        requirementType: 'string',
        validateString: function validateString(value, requirement) {
            var p = value.replace(/\D+/g, "");
            if (p.length == 11) {
                return true;
            } else {
                return false;
            }
        },
        messages: {
            ru: 'Некорректный номер телефона',
            en: 'Incorrect phone number'
        }
    });

    $('input[type=tel]').each(function (i, el) {
        var phone = $(this);
        $(phone).inputmask({
            mask: '+7 (999) 999-99-99',
            showMaskOnHover: false,
            oncomplete: function oncomplete() {
                $(this).addClass('tel-valid');
            },
            onincomplete: function onincomplete() {
                $(this).removeClass('tel-valid');
            }
        });
    });

    $('.time input').each(function (i, el) {
        var time = $(this);
        $(time).inputmask("hh:mm",{
            placeholder: "00:00",
            showMaskOnHover: false,
            oncomplete: function oncomplete() {
                $(this).addClass('time-valid');
            },
            onincomplete: function onincomplete() {
                $(this).removeClass('time-valid');
            }
        });
    });

    $('form button').on('click', function() {
        event.preventDefault();
        var button = $(this);
        var form = button.parents('form');
        var isValid = form.parsley().validate();
        if(isValid && !form.hasClass('success')) {
            button.text('подождите..');
            $.ajax({
                type: form.attr('method'),
                data: form.serialize(),
                url: form.attr('action')
            }).done(function(response) {
                var response = JSON.parse(response);
                switch (response['status']) {
                    case 1:
                        form.addClass('success');
                        button.text('отправлено!');
                        break;
                    case 0:
                        alert('При отправке формы произошла ошибка! Пожалуйста, проверьте правильность заполненных полей или же обратитесь к администратору')
                        break;
                }
            });
        }
        return false;
    })
}

// $('.js-smartbanner-close').click(function () {
//     $('.smartbanner_android, .smartbanner_ios').hide();
// });

// $('.js-smartbanner-download').click(function () {
//     Cookies.set('smartbannerCookie', 'div has been closed', {
//         expires: 60
//     });
//     setTimeout(() => {
//         $('.smartbanner_android, .smartbanner_ios').hide();
//     }, 300);
// });

// $(document).ready(function () {
//     var smartbannerCookie = Cookies.get('smartbannerCookie');

//     console.log(smartbannerCookie);

//     if (typeof Cookies.get('smartbannerCookie') === 'undefined') {
//         setTimeout(() => {
//             if (device.ios()) {
//                 $('.smartbanner_ios').show();
//             } else if (device.android()) {
//                 $('.smartbanner_android').show();
//             }
//         }, 300);
//     } else {
//         $('.smartbanner_ios').hide();
//         $('.smartbanner_android').hide();
//     }
// });